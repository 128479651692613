import React from "react"

export const TeamList = () => {
  return (
    <div className="team-wrapper">
      <div className="team-item">
        <div className="team-photo">
          <img src="img/dest/icon-team-1.svg" alt="" />
        </div>
        <h4>Design</h4>
      </div>
      <div className="team-item">
        <div className="team-photo">
          <img src="img/dest/icon-team-2.svg" alt="" />
        </div>
        <h4>Engineering</h4>
      </div>
      <div className="team-item">
        <div className="team-photo">
          <img src="img/dest/icon-team-3.svg" alt="" />
        </div>
        <h4>Finance</h4>
      </div>
      <div className="team-item">
        <div className="team-photo">
          <img src="img/dest/icon-team-4.svg" alt="" />
        </div>
        <h4>HR</h4>
      </div>
      <div className="team-item">
        <div className="team-photo">
          <img src="img/dest/icon-team-5.svg" alt="" />
        </div>
        <h4>IT</h4>
      </div>
      <div className="team-item">
        <div className="team-photo">
          <img src="img/dest/icon-team-6.svg" alt="" />
        </div>
        <h4>Legal</h4>
      </div>
      <div className="team-item">
        <div className="team-photo">
          <img src="img/dest/icon-team-7.svg" alt="" />
        </div>
        <h4>Operations</h4>
      </div>
      <div className="team-item">
        <div className="team-photo">
          <img src="img/dest/icon-team-8.svg" alt="" />
        </div>
        <h4>Recruiting</h4>
      </div>
    </div>
  )
}
